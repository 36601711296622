  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-3-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert batiment Aix en provence</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expert indépendant en bâtiment à Aix-en-Provence</h1>

<p>Le cabinet RV Expertises, Experts bâtiments indépendants, vous propose ses solutions expertales pour vos besoins d’accompagnement en gestion de sinistre ou pour tout avis technique sur un ouvrage présentant des désordres.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert batiment Aix en provence" />
</div>


<p>Quelque soit vos attentes et vos objectifs, nous mettons tout en œuvre afin de répondre au mieux à vos attentes et vous proposer les solutions techniques les plus adaptées.</p>
<h2>Pourquoi faire intervenir un expert en bâtiment ?</h2>

<p>Le recours à un expert bâtiment est souvent nécessaire face à une pathologie du bâtiment (fissures, humidité, malfaçons). </p>

<p>Le domaine de l’expertise s’adresse à des clients, comme des particuliers, entreprises ou assurés qui sont confrontés à une problématique immobilière et ont un besoin spécifique d’accompagnement et d’orientation comme par exemple, la détermination de cause de désordres et les travaux les plus adaptés pour y remédier.</p>

<h2>Expert bâtiment à Aix-en-Provence</h2>

<p>Depuis 2018, le cabinet RV Expertises intervient auprès des assurés sinistrés et auprès des particuliers et entreprises dans leur besoin de conseils techniques et d’accompagnement en gestion de sinistre.</p>

<p>Nos prestations : </p>

<ul><li>Expertise Construction : désordres fissures, humidité, avis avant acquisition, malfaçons,</li>
<li>Expertise d’assuré : assistance de l’assuré en litige et conflits - recours amiable,</li>
<li>Assistance Expertise judiciaire :  conseils techniques à Avocat, assistance technique de l’assuré,</li>
<li>Avis technique général tout type d’ouvrage : maison individuelle, immeuble, mur de soutènement,</li>
<li>Gestion de sinistre post catastrophe naturelle : sécheresse, inondation, mouvements de terrain.</li></ul>


<p>En fonction de votre besoin et de votre contexte d’étude, nous mettons en place une expertise personnalisée et adaptée à votre problématique afin d’en optimiser son efficacité.</p>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert bâtiment</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-batiment-aix-en-provence/' className='active'>Expert batiment Aix en provence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details